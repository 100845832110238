import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { CardHelper } from "./card-helper";
import { CardBrand, CardNumberLength, CCVLength } from "./enums";

export class CustomValidators {
  static validateCreditCard(control: AbstractControl): ValidationErrors | null {
    const cardNumber: string = control.value?.trim() || "";

    if (!cardNumber) {
      return null;
    }

    const sanitizedCardNumber = cardNumber.replace(/\D/g, "");

    if (
      sanitizedCardNumber.length < CardNumberLength.MIN ||
      sanitizedCardNumber.length > CardNumberLength.MAX
    ) {
      return { cardLength: true };
    }

    if (!CardHelper.luhnCheck(sanitizedCardNumber)) {
      return { invalidCard: true };
    }

    const cardType = CardHelper.getTypeByCardNumber(sanitizedCardNumber);
    if (!cardType) {
      return { invalidCardType: true };
    }

    return null;
  }

  static validateCCV(formControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const ccv: string = control.value?.trim() || "";
      const cardNumber: string =
        control.parent?.get(formControlName)?.value?.trim() || "";
      const cardType = CardHelper.getTypeByCardNumber(cardNumber);

      if (cardType === CardBrand.AMEX && ccv.length !== CCVLength.AMEX) {
        return { ccvLength: true, cardType };
      } else if (cardType !== CardBrand.AMEX && ccv.length === CCVLength.AMEX) {
        return { ccvLength: true, cardType };
      }

      return null;
    };
  }
}

export enum CardBrand {
  VISA = "VISA",
  MASTERCARD = "MASTERCARD",
  AMEX = "AMERICAN EXPRESS - AMEX",
  BANCO_DO_BRASIL = "BANCO DO BRASIL",
  HSBC = "HSBC",
  HIPERCARD = "HIPERCARD",
  DINNERS = "DINNERS",
  DISCOVER = "DISCOVER",
  ITAU = "ITAU",
  SANTADER = "SANTADER",
  CAIXA_ECONONICA = "CAIXA ECONONICA",
  ELO = "ELO",
  HIPER = "HIPER",
}

export enum CardNumberLength {
  MIN = 13,
  MAX = 16,
}

export enum CCVLength {
  AMEX = 4,
  STANDARD = 3,
}

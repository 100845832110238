import { CardBrand } from './enums';

export class CardHelper {

  static getTypeByCardNumber(cardNumber: string): CardBrand {
    if (!cardNumber) return null
    switch (true) {
      case cardNumber.startsWith("4"):
        return CardBrand.VISA;

      case cardNumber.startsWith("5") && cardNumber[1] >= "1" && cardNumber[1] <= "5":
        return CardBrand.MASTERCARD;

      case cardNumber.startsWith("3") && (cardNumber[1] === "4" || cardNumber[1] === "7"):
        return CardBrand.AMEX;

      default:
        return CardBrand.MASTERCARD;
    }
  }

  static luhnCheck(cardNumber: string): boolean {
    let sum = 0;
    let shouldDouble = false;

    for (let i = cardNumber.length - 1; i >= 0; i--) {
      let digit = parseInt(cardNumber.charAt(i), 10);

      if (shouldDouble) {
        digit *= 2;
        if (digit > 9) {
          digit -= 9;
        }
      }

      sum += digit;
      shouldDouble = !shouldDouble;
    }

    return sum % 10 === 0;
  }
}
